<template>
  <div class="flip-container">
    <div class="login-screen">
      <span class="main-logo" :style="{ backgroundImage: `url(${config.SITE_LOGO_URL})` }"></span>
      <div class="login-block row middle-xs">
        <span
          class="loginimg"
          :style="{ backgroundImage: `url(${config.LOGIN_PAGE_IMAGE_URL})` }"
        ></span>
        <div class="login-info">
          <span class="ftitle"
            >Complete user setup for <br />
            {{ email }}</span
          >
          <label class="flabel">Full Name</label>
          <k-text-input
            ref="name"
            class="login-input"
            :required="true"
            :default-focus="true"
            :value="name"
            @change="onChangeName"
            @validation="onValidation"
          />
          <label class="flabel">New Password</label>
          <k-text-input
            class="login-input"
            :required="true"
            type="password"
            @change="onChangePassword"
            @validation="onValidation"
          />
          <label class="flabel">Confirm Password</label>
          <k-text-input
            class="login-input"
            :required="true"
            type="password"
            @change="onChangeConfirmPassword"
            @validation="onValidation"
          />
          <k-button :size="4" label="Continue" @click="onClickContinue" />
        </div>
        <div v-if="error" class="error">Error: {{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { isBlank } from 'adready-api/helpers/common';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import config from '~/config';

export default {
  name: 'CreatePassword',

  mixins: [validationsMixin.events],

  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
    userId: {
      required: true,
      type: String,
    },
    token: {
      required: true,
      type: String,
    },
    email: {
      required: true,
      type: String,
    },
    inputFullName: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      name: null,
      password: null,
      confirmPassword: null,
      errorMsg: null,
      config,
      mode: this.$route.query.mode,
    };
  },

  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },

  created() {
    if (!this.userId || isBlank(this.token)) {
      this.$router.replace({ name: 'Login' });
    }
    this.name = this.inputFullName;
  },

  methods: {
    isFlipPlus() {
      return this.mode && this.mode === 'fp';
    },

    onChangeName(val) {
      this.name = val;
    },

    onChangePassword(val) {
      this.password = val;
    },

    onChangeConfirmPassword(val) {
      this.confirmPassword = val;
    },

    onClickContinue() {
      if (isBlank(this.name)) {
        this.$refs.name.focus();
        return;
      }

      if (isBlank(this.password) || this.password.length < 6) {
        this.errorMsg = 'Password cannot be blank. Must be at least 6 characters.';
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.errorMsg = 'Passwords do not match';
        return;
      }

      this.errorMsg = null;

      axios
        .post(
          `${config.ADREADY_URL}/api/auth/create_password`,
          {
            userId: this.userId,
            token: this.token,
            name: this.name,
            password: this.password,
          },
          { withCredentials: true }
        )
        .then(() => {
          window.location = this.isFlipPlus() ? config.FLIP_PLUS_URL : config.FLIP_URL;
        })
        .catch((error) => {
          this.errorMsg = 'failed to create password';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.ftitle {
  max-width: 270px;
  text-align: left;
}
</style>
<style lang="scss" scoped>
.flip-container {
  background-color: #131619;
  width: 100vw !important;
  height: 100vh;
  margin-bottom: -40px;
}
::v-deep .form-input .form-input-field {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #dadada !important;
  background-color: #fff !important;
  color: #000 !important;
}
::v-deep .k-btn.btn-size-4 {
  padding: 0 !important;
  background-color: var(--defaultprimarycolor) !important;
  font-size: 14px !important;
  // padding: 20px 18px !important;
  border-radius: 0 !important;
  border: 0px;
  cursor: pointer;
  appearance: none;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 0.15em !important;
  line-height: 1.2em;
  display: inline-block;
  font-weight: 400 !important;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 214px !important;
  user-select: none;
  span.label {
    padding: 14px 22px !important;
  }
  &:hover {
    background-color: #424242 !important;
    color: #fff !important;
  }
}
</style>
